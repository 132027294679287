<template>
  <div class="release-order-container">
    <UDLayout :margin="16">
      <PageHeader slot="top" title="发布工单" :back="handleBack" />
      <BGCard slot="bottom" :padding="'0 173px'">
        <div class="warp">
          <div class="layout">
            <div class="white-bg right">
              <PcParser
                v-if="formDesignData"
                :key="key"
                ref="dialogForm"
                :form-data="formDesignData"
                :displayData="displayData"
                :editFields="editFields"
                :disabled="formDisabled"
                :needFlow="needFlow"
                :moduleUuid="moduleUuid"
                :formMode="formMode"
                @submit="onFormSubmit"
                @on-close="onEndFlow"
              ></PcParser>
            </div>
          </div>
        </div>
        <div class="action-row">
          <el-button :loading="btnLoading" class="action-btn" type="primary" @click.stop="handleSubmit">
            提交
          </el-button>
          <el-button :loading="btnLoading" class="action-btn" @click.stop="handleSave">
            保存草稿
          </el-button>
        </div>
      </BGCard>
    </UDLayout>
  </div>
</template>
<script>
import PageHeader from './components/PageHeader.vue'
import UDLayout from './components/UDLayout.vue'
import BGCard from './components/BGCard.vue'
import { dataInterface, transFieldsUUid } from '@/apis/data';
import {uniqid} from '@/utils/tools.js'
import { mapGetters } from "vuex";
import eventBus from "@/plugins/eventBus";
// import { dataInterface } from '@/api/data'
/**
 * @description 发布工单
 */
export default {
  name: 'ReleaseOrder',
  components: { PageHeader, UDLayout, BGCard },
  data() {
    return {
      btnLoading: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      btnActionType: '',
      formID: 'form629470323f11f',
      selectedUsers: [],
      checkPickerNum: 0,
      key: uniqid(),
      taskEngineId: 0,
    }
  },
  computed:{
    ...mapGetters(["getWorkSpace"])
  },
  created() {
    let tempData = localStorage.getItem('tempWorkOrder')
    if(tempData && tempData !== 'null' && tempData !== ''){
      this.$confirm('您是否载入草稿数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.displayData = JSON.parse(tempData)
        this.key = uniqid()
      })
    }
    this.initFormDesign()
  },
  mounted() {
    eventBus.$on('selfpicker',(selected,total)=>{
      this.selectedUsers = selected
      this.checkPickerNum = total
    })
  },
  methods: {
    initFormDesign() {
      transFieldsUUid(this.formID).then(
        (res) => {
          if (res.data.code == 200) {
            this.formDesignData = res.data.data
          }
        }
      );
    },
    handleBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.btnActionType = 'submit'
      this.$refs.dialogForm.handleSubmit()
    },
    handleSave() {
      this.btnActionType = 'save'
      this.$refs.dialogForm.handleSubmit()
    },
    onEndFlow() {

    },
    onFormSubmit(formData) {
      formData.field62946c2a8b55e = this.getWorkSpace.work_space_id
      //任务引擎责任人判断提示
      if (
        this.checkPickerNum !== 0 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('任务引擎责任人未选择完整！')
        return false
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }
      if (this.btnActionType === 'submit') {
        console.log('提交', formData)
        let data = {
          __method_name__: "createData",
          object_uuid: 'object62946b8979e52',
          transcode:0,
          ...formData,
        }
        this.btnLoading = true
        dataInterface(data).then(res => {
          if(res.data.code === 200) {
            localStorage.removeItem('tempWorkOrder')
            this.$message.success(res.data.msg)
            this.$router.go(-1)
          }
        }).finally(() => {
          this.btnLoading = false
        })
      } else {
        localStorage.setItem('tempWorkOrder',JSON.stringify(formData))
        this.$message.success('保存草稿成功！');
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .release-order-container{
    width: 100%;
    height: 100%;
    background: #F2F4F7;
    .warp{
      height: calc(100% - 64px);

      .layout{
        width: 100%;
        height: 100%;
      }
    }
    .action-row{
      width: 100%;
      height: 64px;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 24px;
      border-top: 1px solid #F1F1F1;
      box-sizing: border-box;
      .action-btn{
        padding: 7px 20px;
        border-radius: 6px;
      }
    }
    .white-bg{
      background: #FFF;
    }
    .left{
      width: 888px;
      .tie-label{
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        .label{
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #272829;
          line-height: 22px;
        }
      }
    }
    .right{
      height: 100%;
      width: 100%;
      border-left: 1px solid #F1F1F1;
      padding: 0px 10px;
      box-sizing: border-box;
      overflow-y: auto;
    }
  }
</style>
